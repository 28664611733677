// @flow

import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import {
  Layout,
  LinkedButton,
  MarkdownPs,
  Section,
  SectionTestimonialSquare,
  TabMenu,
  TextHeading,
  QuestionAnswer,
} from '../../components';
import { Dimen } from '../../constants';
import { unwrapConnection } from '../../util';

import type { PageFaqGuest } from '../../__generated__/PageFaqGuest';

type Props = $ReadOnly<{|
  data: PageFaqGuest,
|}>;

const faqGuest = ({
  data: { page, faqConnection, testimonialLeft, testimonialRight },
}: Props) => {
  if (!page) throw new Error('PageFaqGuest missing from CMS');
  const questions = unwrapConnection(faqConnection);
  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <TabMenu
        links={[
          { url: '/faq/host', label: "View Host Faq's", highlight: false },
          {
            url: '/faq/guest',
            label: "View Guest Faq's",
            highlight: true,
          },
        ]}
      />
      <Section>
        <StyledHeader>
          <TextHeading level={3} tag="h2">
            Frequently Asked Questions by Guests
          </TextHeading>
        </StyledHeader>
      </Section>
      <Section>
        <Questions>
          {questions &&
            questions.map(
              question =>
                question.guest && (
                  <QuestionAnswer key={question.id} faq={question} />
                )
            )}
        </Questions>
      </Section>
      <Section backgroundColor="grey">
        <StyledLeadingText>
          <TextHeading level={1} tag="h1">
            {page.heading}
          </TextHeading>
          {page.leadingText && <MarkdownPs html={page.leadingText} />}
          <LinkedButton label={page.buttonLabel} link={page.buttonLink} />
        </StyledLeadingText>
      </Section>
      <Testimonials>
        {testimonialLeft && (
          <SectionTestimonialSquare
            testimonial={testimonialLeft}
            backgroundColor="darkBlue"
          />
        )}
        {testimonialRight && (
          <SectionTestimonialSquare
            testimonial={testimonialRight}
            backgroundColor="darkBlue"
          />
        )}
      </Testimonials>
    </Layout>
  );
};
export default faqGuest;

export const query = graphql`
  query PageFaqGuest {
    page: datoCmsPageFaqGuest {
      heading
      leadingText
      buttonLabel
      buttonLink
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    faqConnection: allDatoCmsFaq {
      edges {
        node {
          id
          guest
          ...QuestionAnswer_faq
        }
      }
    }

    testimonialLeft: datoCmsTestimonial(identifier: { eq: "faqGuest_1" }) {
      ...SectionTestimonialSquare_testimonial
    }

    testimonialRight: datoCmsTestimonial(identifier: { eq: "faqGuest_2" }) {
      ...SectionTestimonialSquare_testimonial
    }
  }
`;

const Questions = styled('div')`
  padding-bottom: ${rem(Dimen.spacing * 2)};
`;

const Testimonials = styled('div')`
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const StyledLeadingText = styled('div')`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;

const StyledHeader = styled('div')`
  text-align: center;
  margin-top: ${rem(Dimen.headerHeight)};
`;
